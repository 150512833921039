import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import CategoriesDropdown from "../../../../shared/components/selectDropdown/CategoriesDropdown";
import {
  buttonNameConstants,
  buttonTypeConstants,
  categoryDropdownConstants,
  toastMessages,
  toastType,
  transactionConstants,
} from "../../../../constants";
import { NFCIcon } from "../../../../assets/icons/iconsProvider";
import {
  customToast,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import { STORE_CARD_PAYMENT_TYPE_ENUMS } from "../../../../system/globalEnums";

const CardPayment = ({
  amountToPay,
  spinnerArray,
  currentStore,
  storeDevices,
  paymentDevice,
  transactionData,
  setPaymentMethod,
  handleThirdPartyPayment,
  handleCreditCardPayment,
  handleTransactionCancel,
  activeCardPaymentTypeProp,
  handleCancelButtonClick = () => {},
}) => {
  const activeCardPaymentType =
    activeCardPaymentTypeProp || currentStore?.activeCardPaymentType;
  const [storeVerifoneDevices, setStoreVerifoneDevices] = useState(
    storeDevices?.length > 0
      ? storeDevices.map((device) => ({
          value: device.serialNumber,
          label: device.poiId,
        }))
      : []
  );
  const [selectedDevice, setSelectedDevice] = useState(
    storeVerifoneDevices.length > 0 && storeVerifoneDevices[0]
  );

  const handlePayFromDeviceClick = () => {
    if (selectedDevice?.value) {
      handleCreditCardPayment(selectedDevice?.value);
    } else {
      customToast(toastMessages.PLEASE_SELECT_DEVICE, toastType.ERROR);
    }
  };

  const handleContinueButtonClick = () => {
    handleThirdPartyPayment();
  };

  const handleCancelBtnClick = () => {
    setPaymentMethod("");
    handleCancelButtonClick();
  };

  useEffect(() => {
    setStoreVerifoneDevices(
      storeDevices?.length > 0
        ? storeDevices.map((device) => ({
            value: device.serialNumber,
            label: device.poiId,
          }))
        : []
    );
    // eslint-disable-next-line
  }, [storeDevices]);

  return (
    <div className="payment-cash-card-wrapper">
      <Row className="m-0">
        <Col
          md={12}
          className="d-flex justify-content-between align-items-center"
        >
          <span className="cash-heading">Credit</span>
          {amountToPay < transactionData.totalAmountToPay && (
            <span className="item-count-chip">
              ${parseToThousandSeperatorDecimalNumber(amountToPay)}
            </span>
          )}
        </Col>
        {activeCardPaymentType === STORE_CARD_PAYMENT_TYPE_ENUMS.VERIFONE ? (
          <Col md={12} className="mt-3">
            {paymentDevice ? (
              <div className="receipt-total-wrapper d-flex flex-column justify-content-center align-items-center gap-3 p-5">
                <NFCIcon />
                <span className="verifone-heading">
                  Use verifone device to complete transaction
                </span>
                <Button
                  type="button"
                  label={buttonNameConstants.CANCEL_THE_TRANSACTION}
                  handleClick={() => {
                    handleTransactionCancel();
                  }}
                  buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                  className="w-100"
                ></Button>
              </div>
            ) : (
              <>
                <div className="receipt-total-wrapper d-flex flex-column gap-1">
                  <span>Select Verfione Device</span>
                  <CategoriesDropdown
                    className="w-100 mt-1"
                    options={storeVerifoneDevices}
                    handleChange={(selectedOption) => {
                      setSelectedDevice(selectedOption);
                    }}
                    noOptionsMessage="No Device Found"
                    placeholder="Select Payment Device"
                    SelectIconImage={categoryDropdownConstants.POS_DEVICE_ICON}
                    isClearable={false}
                    defaultValue={
                      storeVerifoneDevices.length > 0 && storeVerifoneDevices[0]
                    }
                  />
                </div>
                <Button
                  type="button"
                  label={buttonNameConstants.PAY}
                  handleClick={() => {
                    handlePayFromDeviceClick();
                  }}
                  className="w-100 mt-2"
                />
                <Button
                  type="button"
                  label={buttonNameConstants.CANCEL}
                  handleClick={handleCancelBtnClick}
                  buttonType={buttonTypeConstants.GHOST_BUTTON}
                  className="w-100 mt-2"
                />
              </>
            )}
          </Col>
        ) : (
          <Col md={12}>
            <div className="w-100 p-3 d-flex justify-content-center align-items-center">
              <span className="w-100 p-3 text-center">
                Use Card Reader to Capture Payment
              </span>
            </div>
            <Button
              type="button"
              label={buttonNameConstants.CONTINUE}
              handleClick={() => {
                handleContinueButtonClick();
              }}
              isDisabled={
                isSpinnerEnabled(
                  spinnerArray,
                  transactionConstants.GET_RETURN_ID
                ) ||
                isSpinnerEnabled(
                  spinnerArray,
                  transactionConstants.MAKE_TRANSACTION
                )
              }
              className="w-100 mt-2"
            />
            <Button
              type="button"
              label={buttonNameConstants.CANCEL}
              handleClick={handleCancelBtnClick}
              buttonType={buttonTypeConstants.GHOST_BUTTON}
              className="w-100 mt-2"
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default CardPayment;
