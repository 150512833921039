import React from "react";
import { AddIcon } from "../../../assets/icons/iconsProvider";
import {
  getProductDetailCardTags,
  isProductTrackQuantity,
  parseToThousandSeperatorDecimalNumber,
} from "../../utility";
import { inventoryConstants } from "../../../constants";
import { TRADING_CARD_CATEGORIES_ENUMS } from "../../../system/globalEnums";

const ProductDetailCard = (props) => {
  const {
    handleCardClick,
    productName,
    skuNumber,
    productType,
    price,
    key,
    productObject,
    isNewTransaction = false,
    isSaleOutOfStock,
  } = props;

  const canSellProduct = () => {
    // Allow sale if isSaleOutOfStock is true (regardless of quantity or trackQuantity)
    if (isSaleOutOfStock) {
      return true;
    }

    // Allow sale if trackQuantity is false
    if (!productObject.trackQuantity) {
      return true;
    }

    // Do not allow sale if quantity <= 0
    if (Number(productObject.quantity) <= 0) {
      return false;
    }

    return true;
  };

  return (
    <div
      key={key}
      className={`searched-product-card d-flex align-content-center justify-content-between mb-2 ${
        canSellProduct() ? "" : "opacity-50 cursor-not-allowed"
      }`}
      onClick={() => {
        if (!canSellProduct()) {
          return;
        }
        handleCardClick(productObject);
      }}
    >
      <div className="product-card-detail-left-div d-flex align-items-center gap-2">
        <span className="product-name-detail-sale">
          {productObject.category_name}
        </span>
        <div className="product-name-wrapper d-flex flex-column justify-content-center">
          <span>{productName}</span>
          {productObject?.subcategory ===
            TRADING_CARD_CATEGORIES_ENUMS.SYSTEM &&
            productObject?.serialNumber && (
              <span className="pe-1 opacity-50 serial-number-text">
                S/N : {productObject?.serialNumber}
              </span>
            )}
        </div>
        <span className="text-nowrap d-flex align-items-center justify-content-center gap-3">
          {isNewTransaction && ` ${productObject.type}`}{" "}
          {isNewTransaction &&
            getProductDetailCardTags(
              productObject.tags?.filter(
                (tag) => tag !== inventoryConstants.CUSTOM_ITEM_TAG
              ),
              productObject.sku
            )}
        </span>
        <span>{productType}</span>
        {productObject?.sku?.length && isProductTrackQuantity(productObject) ? (
          <span className="text-nowrap">Qty: {productObject?.quantity}</span>
        ) : (
          ""
        )}
      </div>
      <div className="product-card-detail-right-div">
        <button type="button" className="add-product-button">
          <AddIcon className="add-button-icon" />
        </button>
        <span>{`$${parseToThousandSeperatorDecimalNumber(price)}`}</span>
      </div>
    </div>
  );
};

export default ProductDetailCard;
